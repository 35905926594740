import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container} from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
// import CommentForm from './components/CommentForm';s
import FooterTwo from '../../components/FooterTwo.js';
import { Styles } from '../blog/styles/blogDetails.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import { news } from '../../api/agent.js';

const NewsDetails = () => {

    let { id } = useParams();
    const [data,setData]=useState([]);

    useEffect(() => {
        news.getById(id)
          .then((data) => {
            setData(data);
            }).catch((error) => console.log(error));
    });

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper blog-details-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Blog Details" />

                {/* Blog Details */}
                <section className="blog-details-area">
                <Container>
                        <div className="blog-details-box">
                            <div className="blog-details-banner">
                                <img src={process.env.REACT_APP_SERVER + "/files/tc/tcnews/"+data.img} alt="" className="img-fluid" />
                            </div>
                            <div className="heading">
                                <h4>{data.title}</h4>
                            </div>
                            <div className="blog-auth_date d-flex">
                                <div className="post-date">
                                    <p><i className="las la-calendar"></i>{data.created_at}</p>
                                </div>
                            </div>
                            <div className="blog-details-desc">
                                <p>{data.description}</p>
                            </div>
                        </div>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default NewsDetails