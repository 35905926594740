import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/header.js";
import { Language, Menu } from '../api/agent.js';

const Header = () => {



    const [languages, setLanagues] = useState([]);
    const [menu, setMenu] = useState([]);

    let Address = ""; 
    if( localStorage.getItem("lang") === "English")
    {
        Address = "Da Afghanistan Breshna Sherkat (DABS) Dehmazang, Kabul"
    }else if(localStorage.getItem("lang") === "Pashto"){
        Address = "د افغانستان برشنا شرکت دهمزنګ ، کابل افغانستان"
    }else if(localStorage.getItem("lang") === "Dari"){
        Address = "د افغانستان برشنا شرکت دهمزنګ ، کابل افغانستان"
    }

    const email = "info@dabs.af";
    const mobileNmaber = "+93 (0)79 643 0423"
    
    
  
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    
   // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    useEffect(() => {
    
      if( localStorage.getItem("lang") === null)
      {
        localStorage.setItem("lang", 'English');
        localStorage.setItem("lang_value", 'English');
        localStorage.setItem("Dir", 'ltr');
        localStorage.setItem("LangId", 1);
        
      }
      try{
        Language.get()
        .then((data) => {
          setLanagues(data);
        })
        .catch((error) =>{delay(5000); console.log(error);  });
  
      }catch (error) {
      console.error("Error searching:", error);
  
      if (error.response && error.response.status === 429) {
        // If rate-limited, wait for a while and try again
        delay(5000); // Adjust the delay as needed
       // handleSearch(e);
      }
    }
    Menu.get().then((data) => {
        setMenu(data);
    }).catch((error) =>  { console.log(error);  });

      //.finally(() => setLoading(false));
    }, []);
  
    const changeLanguage = (lang,lang_value,dir,id) => {
      localStorage.setItem("lang", lang);
      localStorage.setItem("lang_value", lang_value);
      localStorage.setItem("Dir", dir);
      localStorage.setItem("LangId", id);
  
      window.location.reload();
    };
  
    let listDate = languages.map((val, id) => (
      <Dropdown.Item as="li" onClick={() => changeLanguage(val.Value,val.Name,val.Dir,val.id)} key={id}>
        {val.Name}
      </Dropdown.Item>
    ));

    return (
        <Styles>
            {/* Topbar */}
            <section className="top-bar">
                <Container>
                    <Row>
                        <Col lg="6" md="5">
                            <div className="bar-left">
                                <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item"><i className="las la-map-marker"></i>{Address}</li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg="6" md="7">
                            <div className="bar-right d-flex justify-content-end">
                                <ul className="list-unstyled list-inline bar-social">
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-instagram"></i></a></li>
                                </ul>
                                <ul className="list-unstyled list-inline bar-lang">
                                    <li className="list-inline-item">
                                        <Dropdown>
                                            <Dropdown.Toggle as="a">{localStorage.getItem("lang_value")}<i className="las la-angle-down"></i></Dropdown.Toggle>
                                            <Dropdown.Menu as="ul">
                                                {/* <Dropdown.Item as="li">English</Dropdown.Item>
                                                <Dropdown.Item as="li">دری</Dropdown.Item>
                                                <Dropdown.Item as="li">پښتو</Dropdown.Item> */}
                                                {listDate}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Logo Area */}
            <section className="logo-area">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="logo">
                                <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="" /></Link>
                            </div>
                        </Col>
                        <Col md="9">
                            <div className="logo-contact-box d-flex justify-content-end">
                                <div className="emcontact-box d-flex">
                                    <div className="box-icon">
                                        <i className="flaticon-phone-call"></i>
                                    </div>
                                    <div className="box-content mt-2">
                                        {/* <p>Call Us Now</p> */}
                                        <span>{mobileNmaber}</span>
                                    </div>
                                </div>
                                <div className="emcontact-box d-flex">
                                    <div className="box-icon">
                                        <i className="flaticon-envelope"></i>
                                    </div>
                                    <div className="box-content mt-2">
                                        {/* <p>Enquery Us</p> */}
                                        <span>{email}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Navbar */}
            <section className="main-menu">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="main-menu-box">
                                <div className="menu-box d-flex justify-content-between">
                                    <ul className="nav menu-nav">
                                       { menu.map((val, id) => (
                                            <li className="nav-item" key={id} >
                                                <Link className="nav-link" to={process.env.PUBLIC_URL + val.link}  >{val.name} <i className="las"></i></Link>
                                            </li>
                                        ))}
                                    </ul>

                                    {/* <ul className="nav search-cart-bar">
                                        <li className="nav-item side-box">
                                            <Sidebar />
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Sticky Menu */}
            <StickyMenu data={menu} />

            {/* Mobile Menu */}
            <MobileMenu data={menu} Mobile ={mobileNmaber} Address={Address} />
        </Styles>
    )
}

export default Header
