import React, { useEffect, useState }  from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Pagination from './../../components/Pagination';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/instructor.js';
import { teachers } from '../../api/agent.js';
import HeaderTwo from '../../components/HeaderTwo.js';

const Instructors = () => {

    const [teachersData, setTeachersData] = useState([]);
    const [paginate, setPaginate] = useState(true);
    const [nextCount, setNextCount] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    

    useEffect(() => {
        teachers.get(1)
            .then((data) => {
            setTeachersData(data);
            }).catch((error) => console.log(error));
            setLastPage(teachersData.last_page);
            setPaginate(true);
        
        }, []);
  // get previous page data
  const Prev = (page) => {
    teachers.get(page - 1).then((data) => {
        setTeachersData(data);
      //setData(courses.data);
      setNextCount(page - 1)
    });
  };

  // get next page data
  const Next = (page) => {
    teachers.get(page + 1).then((data) => {
        setTeachersData(data);
        setNextCount(page + 1)
    });
  };


  const handlePageChange = (page) => {
    teachers.get(page)
      .then((data) => {
        setTeachersData(data);
        setLastPage(teachersData.last_page);
        setNextCount(page);
      });
  };

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper instructor-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Instructors" />

                {/* Instructor Area */}
                <section className="instructor-area">
                    <Container>
                        <Row>
                            {
                                teachersData.data &&  teachersData.data.map((data, i) => (
                                    <Col lg="3" md="4" sm="6" key={i}>
                                        <div className="instructor-item">
                                            {/* <a><img src={data.photo} alt="" className="img-fluid" /></a> */}
                                            <img src={data.photo} alt="" className="img-fluid" />
                                            <div className="img-content text-center">
                                                <h5><a>{data.name}</a></h5>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item">{data.email}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }

                            <Col md="12" className="text-center">
                                <Pagination
                                    currentPage={teachersData.current_page}
                                    lastPage={lastPage}
                                    onPrev={() => Prev(teachersData.current_page)}
                                    onNext={() => Next(teachersData.current_page)}
                                    onPageClick={(page) => handlePageChange(page)}
                                 />
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default Instructors