import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import { Styles } from "./styles/aboutUs.js";



const CeoMessage = ({ceo}) => {
    const [openModal,setOpenModal] = useState(false);
    function openModalEvent() {
        setOpenModal(!openModal);
    }
    return (
        <Styles>
            {/* About Us */}
            <section className="about-us">
                <Container>
                { ceo && ceo.map((data, i) => (
                        <Row>
                            <Col md="6">
                                <div className="about-image">
                                    <img src={data.photo} className="main-img" alt="" />
                                    <img src={process.env.PUBLIC_URL + "/assets/images/pattern.png"} className="pattern-img" alt="" />
                                    <div className="video-player" style={{backgroundImage: `url(${data.thumb})`}}>
                                        <ModalVideo channel='youtube' isOpen={openModal} videoId={data.video} onClose={()=>openModalEvent()} />
                                        <button onClick={()=>openModalEvent()} className="play-button"><i className="las la-play"></i></button>
                                    </div>
                                </div>
                            </Col>
                            <Col md="6">
                                    <div className="about-content">
                                        <h4 className="about-title">{data.subject}</h4>
                                        <p className="about-para">{data.message}</p>
                                </div>
                            </Col>
                        </Row>
                        ))
                    }
                    
                </Container>
            </section>
        </Styles>
    )
}

export default CeoMessage
