import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/iconBox.js";


const IconBox = ({mainachievements}) => {
    return (
        <Styles>
            {/* Icon Box */}
            <section className="icon-box-area">
                <Container>
                    <Row>
                        {
                            mainachievements && mainachievements.map((mainachievement) => (
                                <Col md="4" key={mainachievement.id}>
                                    <div className="full-icon-box">
                                        <div className="icon-box d-flex">
                                            <div className="box-icon">
                                                <img src={mainachievement.icon} className={mainachievement.icon} alt={mainachievement.title} width="43px" />
                                            </div>
                                            <div className="box-title">
                                                <h6>{mainachievement.title}</h6>
                                                <p>{mainachievement.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

export default IconBox
