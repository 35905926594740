import React, { Component, useEffect, useState } from 'react';
import Datas from '../../data/gallery/gallery-page.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Pagination from './../../components/Pagination';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/gallery.js';
import { gallary } from '../../api/agent.js';

const Gallery = () => {

    const[gallaryData, setGallaryData]= useState([])
    const [paginate, setPaginate] = useState(true);
    const [nextCount, setNextCount] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    

    useEffect(() => {
        gallary.get(1)
          .then((data) => {
            setGallaryData(data);
            }).catch((error) => console.log(error));

            //setData(gallaryData.data);
            //setLastPage(gallaryData.last_page);
            setPaginate(true);
        
      }, []);

  // get previous page data
  const Prev = (page) => {
    gallary.get(page - 1).then((data) => {
        setGallaryData(data);
      //setData(gallaryData.data);
      setNextCount(page - 1)
    });
  };

  // get next page data
  const Next = (page) => {
    gallary.get(page + 1).then((data) => {
      setGallaryData(data);
      //setData(gallaryData.data);
      setNextCount(page + 1)
    });
  };


  const handlePageChange = (page) => {
    gallary.get(page)
      .then((data) => {
        setGallaryData(data);
        //setData(gallaryData.data);
        setLastPage(gallaryData.last_page);
        setNextCount(page);
      });
  };


  console.log(gallaryData.data)

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper gallery-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Gallery" />

                {/* Gallery Area */}
                <section className="gallery-page-area">
                    <Container>
                        <Row>
                            {
                                gallaryData.data && gallaryData.data.map((data, i) => (
                                    <Col lg="4" sm="6" key={i}>
                                        <div className="gallery-box">
                                            <ModalImage small={process.env.REACT_APP_SERVER + "/files/tc/galleries/" + data.img_thumb} large={data.img} alt="" />
                                        </div>
                                    </Col>
                                ))
                            }

                            <Col md="12" className="text-center">
                                <Pagination  
                                    currentPage={gallaryData.current_page}
                                    lastPage={lastPage}
                                    onPrev={() => Prev(gallaryData.current_page)}
                                    onNext={() => Next(gallaryData.current_page)}
                                    onPageClick={(page) => handlePageChange(page)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default Gallery