import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import "./styles/simmer.css"
import { Link } from 'react-router-dom';

const BlogPostShimmer = () => {
  return (
    <div className="blog-post">
        <Row>
            <Col lg="6" md="12">
                <div className="blog-img shimmerBG media">
                    {/* <Link to="/"><img src="" alt="" className="img-fluid" /></Link> */}
                </div>
            </Col>
            <Col lg="6" md="12">
                <div className="blog-content w-100">
                    <div className="content-box w-100">
                        <div className="top-content d-flex w-100">
                            <div className="blog-date text-center w-100">
                                <p></p>
                            </div>
                            <div className="blog-title w-100">
                                <h6 className='shimmerBG title-line w-100'><Link to="/"></Link></h6>
                            </div>
                        </div>
                        <div className="blog-desk w-100">
                            <p></p>
                            <ul className="list-unstyled list-inline w-100">
                                <li className="list-inline-item shimmerBG content-line w-100"><Link to="/"><i className="las la-user"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
  )
}

export default BlogPostShimmer