
import { Styles } from "./styles/heroSlider.js";
import Swiper from 'react-id-swiper';
import { Container, Row, Col } from 'react-bootstrap';
import { sliders } from '../api/agent.js';
import React, { useEffect, useState } from 'react';

const HeroSlider = () => {
    const [slidersData, setSlidersData] = useState([]);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    useEffect(() => {
        sliders.get()
          .then((data) => {
            setSlidersData(data);
          })
          .catch((error) => {delay(5000); console.log(error);  });
      }, []);

    const settings = {
        slidesPerView: 1,
        loop: true,
        speed: 3000,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        watchSlidesVisibility: true,
        effect: 'fade',
        navigation: {
            nextEl: '.slider-button-next',
            prevEl: '.slider-button-prev'
        },
        renderPrevButton: () => (
            <div className="swiper-btn slider-button-prev"><i className="flaticon-arrow-left-th"></i></div>
        ),
        renderNextButton: () => (
            <div className="swiper-btn slider-button-next"><i className="flaticon-arrow-right-th"></i></div>
        )
    };
    
    return (
        <Styles>
            <section className="hero-slider-area">
                <Swiper {...settings}>
                        {
                            slidersData.map((data, i) => (
                                <div className="slider-item" key={i}>
                                    <div className="image-container">
                                        <img src={data.img} className="slider-image" alt={data.title} />
                                    </div>
                                    <div className="slider-table">
                                        <div className="slider-tablecell">
                                            <Container>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="slider-box slider-box1">
                                                            <div className="slider-title">
                                                                {/* <p>{data.title}</p> */}
                                                            </div>
                                                            <div className="slider-desc">
                                                                <h1>{data.title}</h1>
                                                            </div>
                                                            {/* <div className="slider-btn">
                                                                <Link className="slider-btn1" to={process.env.PUBLIC_URL + `/${data.btnOneLink}`}>Our Courses</Link>
                                                                <Link className="slider-btn2" to={process.env.PUBLIC_URL + `/${data.btnTwoLink}`}>Contact Us</Link>
                                                            </div> */}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Swiper>
            </section>
        </Styles>
    );
}

export default HeroSlider;

