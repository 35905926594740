import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
import Pagination from '../../components/Pagination.js';
import FooterTwo from '../../components/FooterTwo.js';
import { Styles } from '../blog/styles/blog.js';
import { news } from '../../api/agent.js';

const NewsGrid = () => {
    const [newsData, setNewsData] = useState([]);
    const [paginate, setPaginate] = useState(true);
    const [nextCount, setNextCount] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    

    useEffect(() => {
        news.get(1).then((data) => {
            console.log(data)

            setNewsData(data);
            }).catch((error) => console.log(error));
            setPaginate(true);
      }, []);

  const Prev = (page) => {
    news.get(page - 1).then((data) => {
      setNewsData(data);
      setNextCount(page - 1)
  });
}

  // get next page data
  const Next = (page) => {
    news.get(page + 1).then((data) => {
      setNewsData(data);
      setNextCount(page + 1)
    });
  };


  const handlePageChange = (page) => {
    news.get(page)
      .then((data) => {
        setNewsData(data);
        setLastPage(newsData.last_page);
        setNextCount(page);
      });
  };


    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper blog-grid-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="News" />

                {/* Blog Classic */}
                <section className="blog-grid-area">
                <Container>
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <Row>
                                    {
                                        newsData.data && newsData.data.map((data, i) => (
                                            <Col lg="4" md="3" key={i}>
                                                <div className="blog-item">
                                                    <div className="blog-img">
                                                        <Link to={process.env.PUBLIC_URL +"/news-details/"+ data.id}><img src={data.imgThumb} alt="" className="img-fluid" /></Link>
                                                    </div>
                                                    <div className="blog-content">
                                                        <div className="blog-auth_date d-flex">
                                                            <div className="author-img d-flex">
                                                                <p><a >{data.author}</a></p>
                                                            </div>
                                                            <div className="post-date">
                                                                <p><i className="las la-calendar"></i> {data.created_at}</p>
                                                            </div>
                                                        </div>
                                                        <div className="blog-title">
                                                            <h6><Link to={process.env.PUBLIC_URL +"/news-details/"+ data.id}>{data.title}</Link></h6>
                                                        </div>
                                                        <div className="blog-desc">
                                                            <p>{data.description.substring(0, 70)}...</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>

                                <div className="text-center">
                                    <Pagination
                                    urrentPage={newsData.current_page}
                                    lastPage={lastPage}
                                    onPrev={() => Prev(newsData.current_page)}
                                    onNext={() => Next(newsData.current_page)}
                                    onPageClick={(page) => handlePageChange(page)}
                                     />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default NewsGrid