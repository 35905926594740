import axios from "axios";
const langeId = localStorage.getItem("LangId") || 1;
axios.defaults.baseURL = "https://admin.dabs.af/api/";
const request = {
  get: async (url) => await axios.get(url).then((response) => response.data),
  post: async (url) => await axios.post(url).then((response) => response.data),
};
const Language = {
  get: () => 
  request.get("lang"),
};
const HomeData = {
  get: () => 
  request.get(`get-home-data/${langeId}`),
};
const AboutData = {
  get: () => 
  request.get(`get-about-us/${langeId}`),
  getCeo: () => 
    request.get(`get-ceo/${langeId}`),
  getHr: () => 
    request.get(`get-hr/${langeId}`),
};
const Menu = {
  get: () => 
  request.get(`get-menu/${langeId}`),
};
const sliders = {
  get: () => 
  request.get(`get-slider/${langeId}`),
};

const CoursesData = {
  get: (pageNumber) => request.get(`get-courses/${langeId}?page=${pageNumber}`),
  getById: (id) => request.get(`get-courses-by-id/${id}`),
};
const teachers = {
  get: (pageNumber) => 
  request.get(`get-teachers?page=${pageNumber}`),
};
const gallary = {
  get: (pageNumber) => 
  request.get(`get-galleries/${langeId}?page=${pageNumber}`),
};
const articles = {
  get: (pageNumber) => 
  request.get(`get-articles/${langeId}?page=${pageNumber}`),
  getById: (id) => 
    request.get(`get-single-article-by-id/${id}`),
};
const news = {
  get: (pageNumber) => 
  request.get(`get-news/${langeId}?page=${pageNumber}`),
  getById: (id) => 
    request.get(`get-news-detail/${id}`),
};

export {
  Language,
  HomeData,
  Menu,
  sliders,
  AboutData,
  CoursesData,
  teachers,
  gallary,
  articles,
  news
};
