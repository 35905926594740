import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/faqEvent.js";
import moment from 'moment';

function FaqEvent({events,faqs}) {
    useEffect(() => {
        const accordionButton = document.querySelectorAll(".accordion-button");
        accordionButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "accordion-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "accordion-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });

    let Upcoming = ""
    let Events =""
    let faq=""
    if( localStorage.getItem("lang") === "English")
        {
            Upcoming = "Upcoming <span>Events</span>"
            faq = "Frequently Ask <span>Question</span>"
        }else if(localStorage.getItem("lang") === "Pashto"){
            Upcoming = "Upcoming <span>Events</span"
            faq = "We Do What We Love To Do. Find the Best Course To Fit Your Needs."
        }else if(localStorage.getItem("lang") === "Dari"){
            Upcoming = "We Do What We Love To Do. Find the Best Course To Fit Your Needs."
            faq = "We Do What We Love To Do. Find the Best Course To Fit Your Needs."
        }

    return (
        <Styles>
            {/* Faq & Event */}
            <section className="event-faq-area">
                <Container>
                    <Row>
                        <Col md="6">
                            <div className="event-area">
                                <Row>
                                    <Col md="12">
                                        <div className="sec-title">
                                            <h4  dangerouslySetInnerHTML={{__html:Upcoming}}/>
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        {
                                            events &&  events.map((event) => (
                                                <div className="event-box d-flex" key={event.id}>
                                                    <div className="event-date text-center">
                                                        <p>{moment(event.StartDate).format('DD MMM')}</p>
                                                    </div>
                                                    <div className="event-details">
                                                        <h6><Link to={event.eventLink}>{event.title}</Link></h6>
                                                        <ul className="list-unstyled list-inline">
                                                            <li className="list-inline-item"><i className="las la-clock"></i>{event.StartDate}</li>
                                                            <li className="list-inline-item"><i className="las la-map-marker"></i>{event.location}</li>
                                                        </ul>
                                                        <p>{event.description}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="faq-area">
                                <div className="sec-title">
                                    <h4 dangerouslySetInnerHTML={{__html:faq}}/>
                                </div>
                                <div className="faq-box">
                                    {
                                        faqs&&faqs.map((faq) => (
                                            <div className="faq-item" key={faq.id}>
                                                <button className="accordion-button active">
                                                    <div className="accordion-icon"><i className="las la-plus"></i></div>
                                                    <p>{faq.title}</p>
                                                </button>
                                                <div className="accordion-content show">
                                                    <p>{faq.description}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

export default FaqEvent
