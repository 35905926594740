import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';


const CourseItemsGrid = ({data}) => {
    return (
        <Fragment>
            {/* Course Item */}
            {
                data && data.map((data,i) => (
                    <Col lg="4" md="4" key={i}>
                        <div className="course-item">
                            <Link to="/">
                                <div className="course-image" style={{ backgroundImage: `url(https://admin.dabs.af/files/tc/courses/${data.ImgThumb})` }}>
                                    <div className="author-img d-flex">
                                        <div className="img">
                                            <img src={'/'} alt="" />
                                        </div>
                                        <div className="title">
                                            <p>{data.authorName}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="course-content">
                                <h6 className="heading"><Link to={`${process.env.PUBLIC_URL + "/course-details/" + data.id}`}>{data.title}</Link></h6>
                                <p className="desc">{data.CourseOutcome}</p>
                                <div className="course-face d-flex justify-content-between">
                                    <div className="duration">
                                        <p><i className="las la-clock"></i>{data.Duration}</p>
                                    </div>
                                    <div className="rating">
                                        <ul className="list-unstyled list-inline">
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                            <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                            </li>
                                            <li className="list-inline-item">{data.Rating}</li>
                                        </ul>
                                    </div>
                                    <div className="student">
                                        <p><i className="las la-chair"></i>{data.students}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))
            }
        </Fragment>
    )
}

export default CourseItemsGrid
