import React, { useState } from 'react';
import Datas from '../data/about-us/about-us.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ModalVideo from 'react-modal-video';
import CountUp from 'react-countup';
import { Styles } from "./styles/aboutUs.js";



const AboutUs = ({recentnews,statistics}) => {
    const [openModal,setOpenModal] = useState(false);
    function openModalEvent() {
        setOpenModal(!openModal);
    }
    return (
        <Styles>
            {/* About Us */}
            <section className="about-us">
                <Container>
                        <Row>
                            <Col md="6">
                            {
                                recentnews && recentnews.map((news) => (
                                    <div className="about-image" key={news.id}>
                                        <img src={news.img} className="main-img" alt="" />
                                        <img src={process.env.PUBLIC_URL + "/assets/images/pattern.png"} className="pattern-img" alt="" />
                                        <div className="video-player" style={{backgroundImage: `url(${news.imgThumb})`}}>
                                            <ModalVideo channel='youtube' isOpen={openModal} videoId={news.videoLink} onClose={()=>openModalEvent()} />
                                            <button onClick={()=>openModalEvent()} className="play-button"><i className="las la-play"></i></button>
                                        </div>
                                    </div>
                                ))
                            }
                            </Col>
                            <Col md="6">
                                    <div className="about-content">
                                    {
                                        recentnews && recentnews.map((news) => (
                                            <React.Fragment key={news.id}>
                                                <h4 className="about-title">{news.title}</h4>
                                                <p className="about-para">{news.description}<span>{Datas.desc2}</span></p>
                                            </React.Fragment>
                                        ))
                                    }
                                    <Row>
                                    {
                                        statistics && statistics.map((statistic,i) => (
                                           
                                                <Col sm="4" key={i}>
                                                    <div className="counter-box box1 text-center">
                                                        <h3><CountUp end={parseInt(statistic.count,10)} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                        <p>{statistic.title}</p>
                                                    </div>
                                                </Col>
                                            
                                        ))
                                    }
                                    </Row>
                                    <Link className="readmore-btn" to={process.env.PUBLIC_URL + "/about"}>Read More</Link>
                                </div>
                            </Col>
                        </Row>
                   
                    
                </Container>
            </section>
        </Styles>
    )
}

export default AboutUs
