import React from 'react';
import Datas from '../data/blog/home-blog.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/homeBlog.js";
import BlogPostShimmer from './BlogPostShimmer.jsx';

const HomeBlog = ({articles}) => {

    if(!articles){
        return(
            <Styles>
            {/* Blog Area */}
            <section className="home-blog-area">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-title text-center">
                                <h4>{Datas.secTitle}</h4>
                            </div>
                        </Col>
                            <Col md="6">
                                <BlogPostShimmer />
                            </Col>
                            <Col md="6">
                                <BlogPostShimmer />
                            </Col>
                            <Col md="6">
                                <BlogPostShimmer />
                            </Col>
                            <Col md="6">
                                <BlogPostShimmer />
                            </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
        )
    }

    return (
        <Styles>
            {/* Blog Area */}
            <section className="home-blog-area">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-title text-center">
                                <h4>{Datas.secTitle}</h4>
                            </div>
                        </Col>
                        {
                            articles && articles.map((article) => (
                                <Col md="6" key={article.id}>
                                    <div className="blog-post">
                                        <Row>
                                            <Col lg="6" md="12">
                                                <div className="blog-img">
                                                    <Link to="/"><img src={article.imgThumb} alt={article.author} className="img-fluid" /></Link>
                                                </div>
                                            </Col>
                                            <Col lg="6" md="12">
                                                <div className="blog-content">
                                                    <div className="content-box">
                                                        <div className="top-content d-flex">
                                                            <div className="blog-date text-center">
                                                                <p>{article.publish_date}</p>
                                                            </div>
                                                            <div className="blog-title">
                                                                <h6><Link to="/">{article.title.substring(0, 50)}...</Link></h6>
                                                            </div>
                                                        </div>
                                                        <div className="blog-desk">
                                                            <p>{article.description.substring(0, 70)}...</p>
                                                            <ul className="list-unstyled list-inline">
                                                                <li className="list-inline-item"><Link to="/"><i className="las la-user"></i>{article.author}</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

export default HomeBlog
