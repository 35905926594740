import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import CourseItemGrid from './components/CourseItemsGrid';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/course.js';
import HeaderTwo from '../../components/HeaderTwo.js';
import { CoursesData } from '../../api/agent.js';
import Pagination from "../../components/Pagination.js"


const CourseGrid = () => {
    const [courses, setCourses] = useState([]);
    const [paginate, setPaginate] = useState(true);
    const [nextCount, setNextCount] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    

    useEffect(() => {
        CoursesData.get(1).then((data) => {

            setCourses(data.courses);
            }).catch((error) => console.log(error));
            setPaginate(true);
            
      }, []);

  const Prev = (page) => {
    CoursesData.get(page - 1).then((data) => {
      setCourses(data.courses);
      setNextCount(page - 1)
  });
}

  // get next page data
  const Next = (page) => {
    CoursesData.get(page + 1).then((data) => {
      setCourses(data.courses);
      //setData(courses.data);
      setNextCount(page + 1)
    });
  };


  const handlePageChange = (page) => {
    CoursesData.get(page)
      .then((data) => {
        setCourses(data.courses);
        //setData(courses.data);
        setLastPage(courses.last_page);
        setNextCount(page);
      });
  };


    return (
        <div className="main-wrapper course-page">

            {/* Header 2 */}
            <HeaderTwo />

            {/* Breadcroumb */}
            <BreadcrumbBox title="Courses" />

            <Styles>
                {/* Course Grid */}
                <section className="course-grid-area">
                    <Container>
                        <Row>
                            {/* <Col lg="3" md="4" sm="5">
                                <CourseSidebar />
                            </Col> */}
                            <Col lg="12" md="12" sm="12">
                                <div className="course-items">
                                    <Row>
                                        <CourseItemGrid data={courses.data} />
                                        <Col md="12" className="text-center">
                                            <Pagination 
                                            currentPage={courses.current_page}
                                            lastPage={lastPage}
                                            onPrev={() => Prev(courses.current_page)}
                                            onNext={() => Next(courses.current_page)}
                                            onPageClick={(page) => handlePageChange(page)}
                                                />
                                            
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <FooterTwo />

        </div>
    )
}

export default CourseGrid