import React from 'react';
import { Styles } from "./styles/pagination.js";

const Pagination = ({ currentPage, lastPage, onPrev, onNext, onPageClick }) => {

    const renderPagination = () => {
        const pages = [];
        const windowSize = 5;
        const halfWindowSize = Math.floor(windowSize / 2);
    
        for (let i = 1; i <= lastPage; i++) {
          if (i === 1 || i === lastPage || (i >= currentPage - halfWindowSize && i <= currentPage + halfWindowSize)) {
            pages.push(
              <li key={i} className={i === currentPage ? "list-inline-item active": "list-inline-item" }>
                <a onClick={() => onPageClick(i)} >{i}</a>
              </li>
            );
          } else if (
            i === currentPage - halfWindowSize - 1 ||
            i === currentPage + halfWindowSize + 1
          ) {
            pages.push(<span key={i}>...</span>);
          }
        }
    
        return pages;
      };


  return (
    <Styles>
        {/* Pagination */}
        <ul className="pagination-box list-unstyled list-inline">
            
            {currentPage > 1 ? (
                <li className="list-inline-item active">
                    <a onClick={onPrev}>
                        <i className="las la-angle-double-left"></i>
                    </a>
                </li>
            ) : (
                <li className="list-inline-item ">
                    <a disaled>
                        <i className="las la-angle-double-left"></i>
                    </a>
                </li>
            )}
           
            {renderPagination()}
        
            {/* <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>1</Link></li>
            <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>2</Link></li>
            <li className="active list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>3</Link></li>
            <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>...</Link></li>
            <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/"}>13</Link></li> */}
        
        {currentPage < lastPage ? (
            <li className="list-inline-item active">
                <a>
                    <i className="las la-angle-double-right"></i>
                </a>
            </li>
        ) : (
            <li className="list-inline-item">
                <a>
                    <i className="las la-angle-double-right"></i>
                </a>
            </li>
        )}
    </ul>
    </Styles>
  )
}

export default Pagination
