import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/courseFilter.js";
import Simmer from './Simmer.jsx';

function CourseFilter({courses}) {

    let title = ""; 
    if( localStorage.getItem("lang") === "English")
    {
        title = "We Do What We Love To Do. Find the Best Course To Fit Your Needs."
    }else if(localStorage.getItem("lang") === "Pashto"){
        title = "موږ هغه څه کوو چې موږ یې خوښوو. ستاسو د اړتیاو د پوره کولو لپاره غوره کورس ومومئ"
    }else if(localStorage.getItem("lang") === "Dari"){
        title = "ما کاری را انجام می دهیم که دوست داریم انجام دهیم. بهترین دوره را متناسب با نیازهای خود بیابید"
    }
    if(!courses){
        return(
            <Styles>
            {/* Course Area */}
            <section className="course-filter">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-title text-center">
                                <h4>{title}</h4>
                            </div>
                        </Col>
                        <Col md="12">
                            <Row className="filter-items">
                                <Col lg="4" md="6">
                                    <Simmer />
                                </Col>
                                <Col lg="4" md="6">
                                    <Simmer />
                                </Col>
                                <Col lg="4" md="6">
                                    <Simmer />
                                </Col>
                                <Col lg="4" md="6">
                                    <Simmer />
                                </Col>
                                <Col lg="4" md="6">
                                    <Simmer />
                                </Col>
                                <Col lg="4" md="6">
                                    <Simmer />
                                </Col>
                            </Row>
                        </Col>
                        <Col md="12" className="text-center">
                            <div className="viewall-btn">
                                <Link to={process.env.PUBLIC_URL + "/course-grid"}>View All Courses</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
        );
    }

    return (
        <Styles>
            {/* Course Area */}
            <section className="course-filter">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-title text-center">
                                <h4>{title}</h4>
                            </div>
                        </Col>
                        <Col md="12">
                            <Row className="filter-items">
                                {
                                    courses && courses.map((course,i) => (
                                        <Col lg="4" md="6" key={i} data-id={course.targetId}>
                                            <div className="course-item">
                                                <Link to="/">
                                                    <div className="course-image" style={{backgroundImage: `url(${course.course_img_thumb})`}}>
                                                        <div className="author-img d-flex">
                                                            <div className="img">
                                                                <img src={course.teacher_photo} alt={course.teacher_name} />
                                                            </div>
                                                            <div className="title" style={{    display: "flex",alignItems: "center",
    justifyContent: "center"}}>
                                                                <p>{course.teacher_name}</p>
                                                                {/* <span>{data.authorCourses}</span> */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="course-price">
                                                            <p>{data.price}</p>
                                                        </div> */}
                                                    </div>
                                                </Link>
                                                <div className="course-content">
                                                    <h6 className="heading"><Link to="/">{course.course_title}</Link></h6>
                                                    <p className="desc">{course.course_outcome}</p>
                                                    <div className="course-face d-flex justify-content-between">
                                                        <div className="duration">
                                                            <p><i className="las la-clock"></i>{course.course_duration}</p>
                                                        </div>
                                                        <div className="rating">
                                                            <ul className="list-unstyled list-inline">
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                                </li>
                                                                <li className="list-inline-item">({course.course_rating})</li>
                                                            </ul>
                                                        </div>
                                                        <div className="student">
                                                            <p><i className="las la-chair"></i>{course.course_enrolled}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                        <Col md="12" className="text-center">
                            <div className="viewall-btn">
                                <Link to={process.env.PUBLIC_URL + "/course-grid"}>View All Courses</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

export default CourseFilter
