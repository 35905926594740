import React, { useEffect, useState } from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FaqEvent from '../../components/FaqEvent';
import { Styles } from "./styles/about.js";
import { AboutData } from '../../api/agent.js';
import Footer from '../../components/Footer.js';
import HrMessage from '../../components/HrMessage.jsx';
import CeoMessage from '../../components/CeoMessage.jsx';


const About = () => {

    const [allData, setAllData] = useState([]);
    const [ceoData, setCeoData] = useState([]);
    const [hrData, setHrData] = useState([]);
    useEffect(() => {

        AboutData.get().then((data) => {
            setAllData(data);
            }).catch((error) =>  { console.log(error);  });

        AboutData.getCeo().then((data) => {
            setCeoData(data);
            }).catch((error) =>  { console.log(error);  });
        AboutData.getHr().then((data) => {
            setHrData(data);
            }).catch((error) =>  { console.log(error);  });
    }, []);

    const {
        events,
        faqs,
    }=allData;

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper about-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="About Us" />

                {/* About Area */}
                <CeoMessage ceo={ceoData} />

                <HrMessage hr={hrData} />

                {/* Icon Box Area */}
                {/* <IconBox mainachievements={mainachievements} /> */}

                {/* Tab Section */}
                {/* <TabBox /> */}

                {/* Testimonial Slider */}
                {/* <TestimonialSlider testemonials={testemonials} /> */}

                {/* Faq & Event Area */}
                <FaqEvent  events={events} faqs={faqs}/>

                {/* Footer 2 */}
                <Footer />

            </div>
        </Styles>
    )
}

export default About