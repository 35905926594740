import Spinner from "react-bootstrap/Spinner";
import React from "react";

const PageLoader = () => {
  return (
    <div className="loader">
        <Spinner animation="grow" />
    </div>
  );
};

export default PageLoader;
