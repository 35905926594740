import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import Search from './common/Search';
import Sidebar from './common/Sidebar';
import StickyMenu from './common/StickyMenu';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/headerTwo.js";
import { Language, Menu } from '../api/agent.js';

const HeaderTwo = () => {

    const [languages, setLanagues] = useState([]);
    const [menu, setMenu] = useState([]);

    let Address = ""; 
    if( localStorage.getItem("lang") === "English")
    {
        Address = "Da Afghanistan Breshna Sherkat (DABS) Dehmazang, Kabul"
    }else if(localStorage.getItem("lang") === "Pashto"){
        Address = "د افغانستان برشنا شرکت دهمزنګ ، کابل افغانستان"
    }else if(localStorage.getItem("lang") === "Dari"){
        Address = "د افغانستان برشنا شرکت دهمزنګ ، کابل افغانستان"
    }

    const email = "info@dabs.af";
    const mobileNmaber = "+93 (0)79 643 0423"
    
    
  
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    
   // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    useEffect(() => {
    
      if( localStorage.getItem("lang") === null)
      {
        localStorage.setItem("lang", 'English');
        localStorage.setItem("lang_value", 'English');
        localStorage.setItem("Dir", 'ltr');
        localStorage.setItem("LangId", 1);
        
      }
      try{
        Language.get()
        .then((data) => {
          setLanagues(data);
        })
        .catch((error) =>{delay(5000); console.log(error);  });
  
      }catch (error) {
      console.error("Error searching:", error);
  
      if (error.response && error.response.status === 429) {
        // If rate-limited, wait for a while and try again
        delay(5000); // Adjust the delay as needed
       // handleSearch(e);
      }
    }
    Menu.get().then((data) => {
        setMenu(data);
    }).catch((error) =>  { console.log(error);  });

      //.finally(() => setLoading(false));
    }, []);
  
    const changeLanguage = (lang,lang_value,dir,id) => {
      localStorage.setItem("lang", lang);
      localStorage.setItem("lang_value", lang_value);
      localStorage.setItem("Dir", dir);
      localStorage.setItem("LangId", id);
  
      window.location.reload();
    };
  
    let listDate = languages.map((val, i) => (
      <Dropdown.Item as="li" onClick={() => changeLanguage(val.Value,val.Name,val.Dir,val.id)} key={i}>
        {val.Name}
      </Dropdown.Item>
    ));



    return (
        <Styles>
            {/* Topbar 2 */}
            <section className="top-bar2">
                <Container>
                    <Row>
                        <Col lg="7" md="9">
                            <div className="bar-left">
                                <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item"><i className="las la-phone"></i>{mobileNmaber}</li>
                                    <li className="list-inline-item"><i className="las la-envelope"></i>{email}</li>
                                    <li className="list-inline-item"><i className="las la-map-marker"></i>{Address}
                                </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg="5" md="3">
                            <div className="bar-right d-flex justify-content-end">
                                <ul className="list-unstyled list-inline bar-lang">
                                    <li className="list-inline-item">
                                        <Dropdown>
                                            <Dropdown.Toggle>{localStorage.getItem("lang_value")}<i className="las la-angle-down"></i></Dropdown.Toggle>
                                            <Dropdown.Menu as="ul">
                                                {listDate}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                </ul>
                                <ul className="list-unstyled list-inline bar-social">
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                    <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Logo Area 2 */}
            <section className="logo-area2">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="logo">
                                <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="" /></Link>
                            </div>
                        </Col>
                        <Col md="9">
                            <div className="menu-box d-flex justify-content-end">
                                <ul className="nav menu-nav">
                                { menu.map((val, i) => (
                                    <li className="nav-item" key={i}>
                                        <Link className="nav-link" to={process.env.PUBLIC_URL + val.link} >{val.name}</Link>
                                    </li>
                                ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Sticky Menu */}
            <StickyMenu data={menu} />

            {/* Mobile Menu */}
            <MobileMenu data={menu} Mobile ={mobileNmaber} Address={Address} />
        </Styles>
    )
}

export default HeaderTwo
