import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/course.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import { CoursesData } from '../../api/agent.js';

function CourseDetails() {
    const [data,setData]=useState([]);
    const { id } = useParams();
    useEffect(() => {
        
        CoursesData.getById(id)
          .then((data) => {
            setData(data);
            }).catch((error) => console.log(error));
    },[]);

    console.log(id)

    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            <HeaderTwo />

            {/* Breadcroumb */}
            <BreadcrumbBox title="Course Details" />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <div className="course-details-top">
                                    <div className="heading">
                                        <h4>{data.title}</h4>
                                    </div>
                                    <div className="course-top-overview">
                                        <div className="d-flex overviews">
                                            <div className="author">
                                                {/* <img src={process.env.PUBLIC_URL + `/assets/images/author.jpg`} alt="" /> */}
                                                <div className="author-name">
                                                    <h6>Author</h6>
                                                    <p>{data.comunicators}</p>
                                                </div>
                                            </div>
                                            <div className="category">
                                                <h6>Category</h6>
                                                <p>{data.tags}</p>
                                            </div>
                                            {/* <div className="rating">
                                                <h6>Rating</h6>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i>
                                                    </li>
                                                    <li className="list-inline-item">(4.5)</li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="course-details-banner">
                                        <img src={process.env.REACT_APP_SERVER + `/files/tc/courses/${data.image}`} alt="" className="img-fluid" />
                                    </div>
                                    <div className="course-tab-list">
                                        <Tab.Container defaultActiveKey="overview">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="overview" className="overview-tab">
                                                    <div className="course-desc">
                                                        <h5>Course Description</h5>
                                                        <p>{data.CourseOutcome}</p>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <FooterTwo />

        </div>
    )
}

export default CourseDetails