import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import HeroSlider from './components/HeroSlider';
import IconBox from './components/IconBox';
import AboutUs from './components/AboutUs';
import CourseFilter from './components/CourseFilter';
import TestimonialSlider from './components/TestimonialSlider';
import FaqEvent from './components/FaqEvent';
import TeamSlider from './components/TeamSlider';
import HomeBlog from './components/HomeBlog';
import Footer from './components/Footer';
import { HomeData } from './api/agent';
 import Simmer from './components/Simmer';
import HeaderTwo from './components/HeaderTwo';

const HomeOne = () => {
    const [allData, setAllData] = useState([]);
    useEffect(() => {
        HomeData.get().then((data) => {
            setAllData(data);
            }).catch((error) =>  { console.log(error);  });
    }, []);
    const {
        mainachievements,
        recentnews,
        statistics,
        courses,
        testemonials,
        articles,
        faqs,
        events,
        teachers
    }=allData;
    console.log(courses)

    return (
        <div className="main-wrapper" >

            {/* Header */}
            <Header />

            {/* Hero Slider */}
            <HeroSlider />

            

            {/* Icon Box */}
            <IconBox mainachievements={mainachievements} />

            {/* About Area */}
            <AboutUs recentnews={recentnews} statistics ={statistics}/>

            {/* Course Filter */}
            <CourseFilter courses={courses} />

            {/* Testimonial Slider */}
            <TestimonialSlider testemonials={testemonials} />

            {/* Faq & Event Area */}
            <FaqEvent events={events} faqs={faqs} />

            {/* Team Slider */}
            <TeamSlider teachers={teachers} />

            {/* Blog Area */}
            <HomeBlog articles ={articles} />

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default HomeOne;

