import React, { Component, useEffect, useState } from 'react';
import Datas from '../../data/blog/grid.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Pagination from './../../components/Pagination';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/blog.js';
import { articles } from '../../api/agent.js';


const BlogGrid = () => {

    const[articalseData, setArticalse]= useState([])
    const [paginate, setPaginate] = useState(true);
    const [nextCount, setNextCount] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    

    useEffect(() => {
        articles.get(1)
          .then((data) => {
            setArticalse(data);
            }).catch((error) => console.log(error));

            //setData(articalseData.data);
            //setLastPage(articalseData.last_page);
            setPaginate(true);
        
      }, []);

  // get previous page data
  const Prev = (page) => {
    articles.get(page - 1).then((data) => {
        setArticalse(data);
      //setData(articalseData.data);
      setNextCount(page - 1)
    });
  };

  // get next page data
  const Next = (page) => {
    articles.get(page + 1).then((data) => {
      setArticalse(data);
      //setData(articalseData.data);
      setNextCount(page + 1)
    });
  };


  const handlePageChange = (page) => {
    articles.get(page)
      .then((data) => {
        setArticalse(data);
        //setData(articalseData.data);
        setLastPage(articalseData.last_page);
        setNextCount(page);
      });
  };

  console.log(articalseData)


    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper blog-grid-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Articalse" />

                {/* Blog Classic */}
                <section className="blog-grid-area">
                    <Container>
                        <Row>
                            <Col lg="12" md="12" sm="12">
                                <Row>
                                    {
                                        articalseData.data && articalseData.data.map((data, i) => (
                                            <Col lg="4" md="3" key={i}>
                                                <div className="blog-item">
                                                    <div className="blog-img">
                                                        <Link to={process.env.PUBLIC_URL +"/"+ data.id}><img src={data.imgThumb} alt="" className="img-fluid" /></Link>
                                                    </div>
                                                    <div className="blog-content">
                                                        <div className="blog-auth_date d-flex">
                                                            <div className="author-img d-flex">
                                                                <p><a >{data.author}</a></p>
                                                            </div>
                                                            <div className="post-date">
                                                                <p><i className="las la-calendar"></i> {data.created_at}</p>
                                                            </div>
                                                        </div>
                                                        <div className="blog-title">
                                                            <h6><Link to={process.env.PUBLIC_URL +"/blog-details/"+ data.id}>{data.title}</Link></h6>
                                                        </div>
                                                        <div className="blog-desc">
                                                            <p>{data.description.substring(0, 70)}...</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>

                                <div className="text-center">
                                    <Pagination
                                    urrentPage={articalseData.current_page}
                                    lastPage={lastPage}
                                    onPrev={() => Prev(articalseData.current_page)}
                                    onNext={() => Next(articalseData.current_page)}
                                    onPageClick={(page) => handlePageChange(page)}
                                     />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default BlogGrid