import React from 'react';
import Datas from '../data/testimonial/testimonial-slider.json';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/testimonialSlider.js";

const TestimonialSlider = ({testemonials}) => {
    let title = ""; 
    if( localStorage.getItem("lang") === "English")
    {
        title = "Lets See What Our Valuable Students Think About Us. Their Testimonials."
    }else if(localStorage.getItem("lang") === "Pashto"){
        title = "راځئ وګورو چې زموږ ارزښتناکه زده کونکي زموږ په اړه څه فکر کوي. د دوی تعریفونه"
    }else if(localStorage.getItem("lang") === "Dari"){
        title = "بیایید ببینیم دانش آموزان ارزشمند ما در مورد ما چه فکر می کنند. توصیفات آنها"
    }

    const settings = {
        slidesPerView: 2,
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        spaceBetween: 30,
        watchSlidesVisibility: true,
        pagination: {
            el: '.slider-dot.text-center',
            clickable: true
        },
        breakpoints: {
            0: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 2
            }
        }
    };

    return (
        <Styles>
            {/* Testimonial Slider */}
            <section className="testimonial-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})` }}>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-title text-center">
                                <h4>{title}</h4>
                            </div>
                        </Col>
                        <Col md="12" className="testimonial-slider">
                            <Swiper {...settings}>
                                {
                                    testemonials && testemonials.map((testemonial) => (
                                        <div className="slider-item" key={testemonial.id}>
                                            <div className="desc">
                                                <h5>{testemonial.title}</h5>
                                                <p>{testemonial.description}</p>
                                            </div>
                                            <div className="writer">
                                                <img src={testemonial.picture} className="slider-image" alt={testemonial.name} />
                                                <h6>{testemonial.name}</h6>
                                                <p>{testemonial.position}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Styles>
    )
}

export default TestimonialSlider
