import React, {useState,useEffect,Suspense} from 'react';
import { BrowserRouter  as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import About from './pages/about/About';
import CourseGrid from './pages/courses/CourseGrid';
import CourseList from './pages/courses/CourseList';
import CourseDetails from './pages/courses/CourseDetails';
import Instructor from './pages/instructor/Instructors';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import Contact from './pages/contact/Contact';
import Faq from './pages/faq/Faq';
import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';
import BlogClassic from './pages/blog/BlogClassic';
import BlogGrid from './pages/blog/BlogGrid';
import BlogDetails from './pages/blog/BlogDetails';
import Product from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Cart from './pages/shop/Cart';
import localContext from "./localContext";
import i18n from "./i18n";
import PageLoader from './PageLoader.js';
import NewsGrid from './pages/news/NewsGrid.js';
import NewsDetails from './pages/news/NewsDetails.js';



function App() {
    const [local,setLocal] = useState("English");
    i18n.on("languageChanged", (lng) => setLocal(i18n.language))
    useEffect(() =>{
    i18n.changeLanguage(localStorage.getItem("lang"));
    })

    return (
        <Router basename="/btc-app">
            <GlobalStyle />
            <ScrollToTop />
            <localContext.Provider value={{local,setLocal}}>
                <Suspense fallback={<PageLoader />}>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={HomeOne} />

                        <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={About} />

                        <Route path={`${process.env.PUBLIC_URL + "/courses"}`} component={CourseGrid} />
                        <Route path={`${process.env.PUBLIC_URL + "/course-details/:id"}`} component={CourseDetails} />

                        <Route path={`${process.env.PUBLIC_URL + "/instructors"}`} component={Instructor} />
                        <Route path={`${process.env.PUBLIC_URL + "/instructor-details"}`} component={InstructorDetails} />

                        <Route path={`${process.env.PUBLIC_URL + "/news"}`} component={NewsGrid} />
                        <Route path={`${process.env.PUBLIC_URL + "/news-details/:id"}`} component={NewsDetails} />

                        <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} component={Gallery} />

                        <Route path={`${process.env.PUBLIC_URL + "/events"}`} component={Events} />
                        <Route path={`${process.env.PUBLIC_URL + "/event-details"}`} component={EventDetails} />
                        
                        <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact} />

                        <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={Faq} />

                        <Route path={`${process.env.PUBLIC_URL + "/404"}`} component={PageNotFound} />
                        
                        <Route path={`${process.env.PUBLIC_URL + "/articles"}`} component={BlogGrid} />
                        <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`} component={BlogDetails} />

                        <Route path={`${process.env.PUBLIC_URL + "/products"}`} component={Product} />
                        <Route path={`${process.env.PUBLIC_URL + "/product-details"}`} component={ProductDetails} />
                        <Route path={`${process.env.PUBLIC_URL + "/cart"}`} component={Cart} />
                    </Switch>
                </Suspense>
            </localContext.Provider> 
        </Router>
    )
}

export default App;