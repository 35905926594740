import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from "./serviceWorker";
import 'swiper/css/swiper.css';
//reactdocm  render the app component in root ele




ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();