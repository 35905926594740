import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/blogDetails.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import { articles } from '../../api/agent.js';

const BlogDetails = () => {

    let { id } = useParams();
    const [data,setData]=useState([]);

    useEffect(() => {
        articles.getById(id)
          .then((data) => {
            setData(data);
            }).catch((error) => console.log(error));
    });



    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper blog-details-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Articalse Details" />

                {/* Blog Details */}
                <section className="blog-details-area">
                    <Container>
                        <div className="blog-details-box">
                            <div className="blog-details-banner">
                                <img src={process.env.REACT_APP_SERVER + "/files/tc/articles/"+data.img} alt="" className="img-fluid" />
                            </div>
                            <div className="heading">
                                <h4>{data.title}</h4>
                            </div>
                            <div className="blog-auth_date d-flex">
                                <div className="author-img d-flex">
                                 <p><Link to={process.env.PUBLIC_URL + "/"}>{data.author}</Link></p>
                                </div>
                                <div className="post-date">
                                    <p><i className="las la-calendar"></i>{data.publish_date}</p>
                                </div>
                            </div>
                            <div className="blog-details-desc">
                                <p>{data.description}</p>
                            </div>
                        </div>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}


export default BlogDetails